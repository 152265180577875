exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-how-to-increase-productivity-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/blog-pages/how-to-increase-productivity.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-how-to-increase-productivity-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-privacy-policy-md": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/markdown-pages/privacy-policy.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-privacy-policy-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-terms-of-service-md": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/markdown-pages/terms-of-service.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-terms-of-service-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-arc-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-arc.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-arc-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-arc-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-arc.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-arc-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-dkn-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-dkn.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-dkn-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-dkn-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-dkn.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-dkn-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-lge-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-lge.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-lge-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-lge-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-lge.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-lge-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mhvy-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-mhvy.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mhvy-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mhvy-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-mhvy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mhvy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-cn-105-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-mitt-cn105.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-cn-105-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-cn-105-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-mitt-cn105.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-cn-105-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-tb-15-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-mitt-tb15.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-tb-15-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-tb-15-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-mitt-tb15.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-mitt-tb-15-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-smg-en-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-smg.en.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-smg-en-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-smg-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kbox-website/src/products/kbox-smg.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-products-kbox-smg-mdx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

